import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { graphql } from "gatsby"
import Metadata from "./metadata"
import "../styles/templates/standard-content.scss"
import ArticleListing from "../components/article-listing"

const NewsListing = ({ data }) => {
    const item = data.kontentItemContentNewsListing.elements
    const sidebarContent = <div className="sc-side"><aside className="rta-side" dangerouslySetInnerHTML={{ __html: item.sidebar_copy.value }} /></div>
    let sidebarPositionCheck = item.sidebar_position.value.length
    let sidebarAboveContent = ""
    let sidebarBelowContent = ""
    let sidebarPosition = ""
    let scWrapperClass = "sc-wrapper"

    if (sidebarPositionCheck > 0) {
        sidebarPosition = item.sidebar_position.value[0].name

        if (sidebarPosition === "above-content") {
            sidebarAboveContent = sidebarContent
            scWrapperClass = "sc-wrapper sc-wrapper__above"
        }
        else if (sidebarPosition === "below-content") {
            sidebarBelowContent = sidebarContent
        }
    }

    return (
        <Layout>
            <Metadata pageData={item} siteData={data.site.siteMetadata} />
            <Head />
            <h1 className="sc-heading">{item.heading.value}</h1>
            <div className={scWrapperClass}>
                {sidebarAboveContent}
                <div className="sc-body-container">
                    <section className="rta-body" dangerouslySetInnerHTML={{ __html: item.body_copy.value }} />
                    <ArticleListing data={data.allKontentItemContentNews.edges} />
                </div>
                {sidebarBelowContent}
            </div>
        </Layout>
    )
}

export default NewsListing

export const query = graphql`
  query newsListingQuery($slug: String!) {
    kontentItemContentNewsListing(fields: { slug: { eq: $slug } }) {
      elements {
        heading {
          value
        }
        body_copy {
          value
        }
        slug {
          value
        }
        sidebar_copy {
          value
        }
        sidebar_position {
          value {
            name
          }
        }
        metadata__title {
          value
        }
        metadata__description {
          value
        }
        metadata__image {
          value {
            url
          }
        }
      }
    }
    allKontentItemContentNews(sort: {fields: elements___date___value, order: DESC}) {
        edges {
          node {
            elements {
              metadata__description {
                value
              }
              heading {
                value
              }
              date {
                value(formatString: "DD MMMM YYYY")
              }
              slug {
                value
              }
            }
          }
        }
      }
    site {
        siteMetadata {
            lang
            siteUrl
            websiteTitle
            description
        }
    }
  }
`